<template>
  <section>
    <b-row fluid>
      <b-col lg="8" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-card"
              ref="cari-gruplar"
              title="Gider Grupları"
              @refresh="refreshStop('cari-gruplar')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">Gider Grupları</h4>
                </b-col>
              </div>
            </b-row>
            <div class="d-flex justify-content-between flex-wrap mt-4">
              <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                    class="unPrint"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height : width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search"/>
              </b-input-group>
            </div>
            <b-table
                striped
                hover
                class="position-relative cari-group-table mt-1"
                :per-page="perPage"
                :current-page="currentPage"
                :items="groupGet"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >
              <template #cell(grupAdi)="data">
                <strong>
                  {{ data.item.grupAdi }}
                </strong>
              </template>
              <template #cell(grupTuru)="data">
                <strong>
                  {{ data.item.grupTuru }}
                </strong>
              </template>
              <template #cell(giderGrupID)="data">
                <b-row>
                  <b-col cols="6">
                    {{ data.item.toplamGider || '0' }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-success"
                        class="btn-icon rounded-circle"
                        v-b-tooltip.hover.left="'Gruba Ait Giderleri Göster'"
                        :to="'giderListesi/' + data.item.giderGrupID"
                    >
                      <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-warning"
                        class="btn-icon rounded-circle"
                        @click="editOn(data.item)"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="grupRemove(data.item.giderGrupID)"
                    >
                      <font-awesome-icon icon="fa-solid fa-xmark"/>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="6">
                <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
              </b-col>
              <b-col cols="6">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 float-right"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-col>
      <b-col v-if="!isEdit" lg="4" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-add"
              ref="cari-grup-add"
              @refresh="refreshStop('cari-grup-add')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">Grup Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-4">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Adı"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          placeholder="Grup Adı"
                          v-model="grupName"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Türü"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        :list="grupTuruOptions"
                        v-model="grupTuru"
                        option-value="text"
                        option-text="text"
                        name="unvan"
                        placeholder="Hızlı Seçim"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1 border-0"
                      @click="grupSave('kayit')"
                      :disabled="!grupName"
                      :style="{backgroundColor: $store.getters.COLORS_GET.bgSuccess+'!important'}"
                  >
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    Kaydet
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="grupTuruDefault"
                  >
                    <feather-icon
                        icon="ResetIcon"
                        class="mr-50"
                    />
                    Sıfırla
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-col>
      <b-col v-if="isEdit" lg="4" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              ref="cari-group-edit"
              class="cari-group-edit"
              @refresh="refreshStop('cari-group-edit')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">{{ editGroupName }} Grup Düzenleniyor</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-4">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Adı"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          placeholder="Grup Adı"
                          v-model="editData.grupAdi"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Turu"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        class=""
                        :list="grupTuruOptions"
                        v-model="editData.grupTuru"
                        option-value="text"
                        option-text="text"

                        name="unvan"
                        placeholder="Hızlı Seçim"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="grupSave('edit')"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    Güncelle
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="isEdit = false"
                  >
                    <feather-icon
                        icon="ResetIcon"
                        class="mr-50"
                    />
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      editGroupName: '',
      oldTransferID: '',
      selectedList: [],
      transferList: [],
      editData: {
        cariGrupID: '',
        grupAdi: '',
        grupTuru: [],
      },
      isEdit: false,
      grupTuruOptions: [
        {
          text: 'Personel',
        }, {
          text: 'Ofis Gideri',
        }, {
          text: 'Tüketim Gideri',
        }, {
          text: 'Diğer Gider',
        },
      ],
      grupTuru: {
        text: 'Personel',
      },
      grupName: '',
      fields: [
        {
          key: 'grupAdi',
          label: 'GRUP ADI',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'grupTuru',
          label: 'GRUP TÜRÜ',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'giderGrupID',
          label: 'Toplam Harcama',
          sortable: false,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    groupGet() {
      return Object.values(store.getters.GIDER_GRUP_LISTESI)
    },
    cariListGet() {
      return Object.values(store.getters.CARI_LIST_GET)
    },
  },
  methods: {
    editOn(item) {
      this.isEdit = true
      this.editGroupName = item.grupAdi
      this.editData.cariGrupID = item.giderGrupID
      this.editData.grupAdi = item.grupAdi
      this.editData.grupTuru = item.grupTuru
    },
    grupRemove(id) {
      this.isEdit = false
      store.dispatch('giderGroupRemove', id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status === 200) {
              store.dispatch('giderGrupListesi')
                  .then(res => {
                    const veri = { ...res }
                    delete veri.userToken
                    this.totalRows = Object.keys(veri).length
                  })
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: res.result.errMsg,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger´',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    grupSave(islem) {
      store.dispatch('giderGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.text : this.editData.grupTuru,
        adi: islem === 'kayit' ? this.grupName : this.editData.grupAdi,
        giderGrupID: islem !== 'kayit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            store.dispatch('giderGrupListesi')
                .then(res => {
                  const veri = { ...res }
                  delete veri.userToken
                  this.totalRows = Object.keys(veri).length
                })
            this.grupName = ''
            const mesaj = store.getters.notificationSettings(res)
            this.isEdit = false
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
            this.grupTuruDefault()
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    grupTuruDefault() {
      this.grupTuru = {
        text: 'Personeller',
      }
    },
  },
  created() {
    store.dispatch('giderGrupListesi')
        .then(res => {
          const veri = { ...res }
          delete veri.userToken
          this.totalRows = Object.keys(veri).length
        })
  },
  beforeDestroy() {
    store.commit({
      type: 'GIDER_GRUP_ACTION',
      durum: 'remove',
    })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.fa-exchange-alt {
  display: none !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-group-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.cari-group-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped>
.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

.search-bar {
  width: 20% !important;
}

@media (max-width: 750px) {
  .search-bar {
    width: 50% !important;
  }
}

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.cari-group-table button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.trash-icon {
  background-color: #F55050 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}
</style>
